import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperMobile from './SwipperMobile'

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swipper.css";
import '../App.css'
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import achimage1 from "../assets/achievements_images/Student.png";
import achimage2 from "../assets/achievements_images/image 28.png";
import achimage3 from "../assets/achievements_images/image 33.png";
import achimage4 from "../assets/achievements_images/image 34.png";
import achimage5 from "../assets/achievements_images/image 35.png";
import achimage6 from "../assets/achievements_images/image 36.png";
import { useEffect } from "react";
import { useState } from "react";

function Swipper() {
    const [slidesPerView, setslidesPerView] = useState(3);

    useEffect(() => {
      resize();
      window.addEventListener("resize", resize);
      function resize(e) {
        var x = window.matchMedia("(max-width: 820px)");
        if (x.matches) {
          setslidesPerView(1);
        } else {
          setslidesPerView(3);
        }
      }
      return () => {
        window.removeEventListener("resize", resize);
      };
    }, []);
    
  return (
    <>

<div className="container ">

{/* <Swiper
  effect={"coverflow"}
  grabCursor={true}
  // spaceBetween={50}
  centeredSlides={false}
  loop={true}
  slidesPerView={slidesPerView}
  coverflowEffect={{
    rotate: 0,
    stretch: -50,
    depth: 100,
    modifier: 2,
  }}
  pagination={{ el: ".swiper-pagination", clickable: true }}
  navigation={{
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    clickable: true,
  }}
  modules={[EffectCoverflow, Pagination, Navigation]}
  className="swiper_container"
>
  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage1} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL STUDENTS</p>
        <p className="score">50+</p>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage2} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL COURSES</p>
        <p className="score">15+</p>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage3} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag"> WORKING ON LIVE PROJECT BY STUDENT</p>
        <p className="score">15+</p>
      </div>
    </div>
  </SwiperSlide>

  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage4} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL STUDENTS</p>
        <p className="score">60+</p>
      </div>
    </div>
  </SwiperSlide>

  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage5} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL DOUBTS SOLVED</p>
        <p className="score">15000+</p>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="box ach-box">
      <div className="box-img">
        <img src={achimage6} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">AVERAGE RESPONSE TIME</p>
        <p className="score">10-20 MIN</p>
      </div>
    </div>
  </SwiperSlide>



  <div className="slider-controler">
    <div className="swiper-button-prev slider-arrow">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </div>
    <div className="swiper-button-next slider-arrow">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </div>
    <div className="swiper-pagination"></div>
  </div>
</Swiper> */}



<Swiper
  // spaceBetween={10}
  
  centeredSlides={false}
  slidesPerView={1}
  // autoplay={{
  //   delay: 2500,
  //   disableOnInteraction: false,
  // }}
  pagination={{
    clickable: true,
  }}
  navigation={true}
  modules={[ Pagination, Navigation]}
  className="mySwiper desktop-Swipper"
>
  <SwiperSlide>
  <div className="box ach-box"  >
      <div className="box-img">
        <img src={achimage1} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL STUDENTS</p>
        <p className="score">50+</p>
      </div>
    </div>
      
      <div className="box ach-box">
      <div className="box-img">
        <img src={achimage2} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL COURSES</p>
        <p className="score">15+</p>
      </div>
    </div>
      <div className="box ach-box">
      <div className="box-img">
        <img src={achimage3} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag"> WORKING ON LIVE PROJECT BY STUDENT</p>
        <p className="score">200+</p>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
  <div className="box ach-box">
      <div className="box-img">
        <img src={achimage4} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">TOTAL DOUBTS SOLVED</p>
        <p className="score">1500+</p>
      </div>
    </div>
      <div className="box ach-box">
      <div className="box-img">
        <img src={achimage5} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag"> AVERAGE RESPOND TIME</p>
        <p className="score">10-20 MIN</p>
      </div>
    </div>
      <div className="box ach-box">
      <div className="box-img">
        <img src={achimage6} alt="slide_image" />
      </div>
      <div className="content">
        <p className="tag">AVERAGE RATING</p>
        <p className="score">4.2</p>
      </div>
    </div>
  </SwiperSlide>
</Swiper>

</div>
<SwiperMobile/>
    </>
  );
}

export default Swipper;
