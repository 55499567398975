import React from "react";
import "./Achievements.css";
import Swipper from "../../components/Swipper";

const Achievements = () => {
  return (
    <>
      <div className="achievements">
        <div className="achievements-title">
          <p>our achievement</p>
        </div>
         {/* <AchievmentsSlider /> */}
 
         <Swipper />
      </div>
      <div className="bottom-border"></div>
    </>
  );
};


export default Achievements;
