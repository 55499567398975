import React from "react";
import "./Highlight.css";
import VerticalLine from './VerticalLine'

//images
import img1 from "../../assets/Course_highlight/1.png";
import img2 from "../../assets/Course_highlight/2.png";
import img3 from "../../assets/Course_highlight/3.png";
import img4 from "../../assets/Course_highlight/4.png";
import img5 from "../../assets/Course_highlight/5.png";
import img6 from "../../assets/Course_highlight/6.png";
import img7 from "../../assets/Course_highlight/7.png";
import img8 from "../../assets/Course_highlight/8.png";

const Highlight = () => {
  return (
    <div className="highlight">
      <div className="highlight_title">
        <h1>course highlight</h1>
      </div>

      <div className="diagram">
      <div className="horizontal-line">
      <div className='circle2'></div>
       <div className='circle2'></div>
      </div>
       
        <VerticalLine left="48%" />
        <VerticalLine left="73%" />
        <VerticalLine left="24%" />
       
       
        <div className="highlights-items">
          <div className="item1">
            <div className="img">
              <img src={img1} alt="no" />
            </div>
            <p>24*7 mentor <br /> support</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img2} alt="no" />
            </div>
            <p>online  <br />/offline mode</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img3} alt="no" />
            </div>
            <p>weekly test</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img4} alt="no" />
            </div>
            <p>work on live <br /> project</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img5} alt="no" />
            </div>
            <p>placement <br /> assistance</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img6} alt="no" />
            </div>
            <p>free online <br /> demo class</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img7} alt="no" />
            </div>
            <p>industrial <br /> certification</p>
          </div>

          <div className="item1">
            <div className="img">
              <img src={img8} alt="no" />
            </div>
            <p>weekly <br /> assignment</p>
          </div>
        </div>
        {/* 
      </div> */}
      </div>
    </div>
  );
};

export default Highlight;
