import React from 'react'

function VerticalLineLine({left, color}) {
  return (
    <div className='vertical-line' style={{left : left, color:color}}>
       <div className='circle'></div>
       <div className='circle'></div>
    </div>
  )
}

export default VerticalLineLine;
