import React from "react";
import "./Criteria.css";

import checkbox from "../../assets/courses_menu/checkbox.png";
import surity from "../../assets/courses_menu/100.png";

const Criteria = () => {
  return (
    <div className="criteria">
      <div className="criteria-title">
        <p>
          
         CODETO INTERNSHIP CRITERIA
        </p>
        <p className="sub-criteria-title">
          A LIMITED SEAT OPPORTUNITY FOR THE INTERNSHIP
        </p>
        </div>


        <div className="list">
          <ul>
            <li>
              <p>• up skills</p>
              <div className="check">
                <img className="checkbox" src={checkbox} alt="internship"/>
                <img  className="sure" src={surity} alt="internship"/>
              </div>
            </li>


            <li>
              <p>• certification</p>
              <div className="check">
                <img  className="checkbox" src={checkbox} alt="internship"/> 
                <img  className="sure" src={surity} alt="criteria"/>
              </div>
            </li>

            <li>
              <p>• 1 to 1 mentorship</p>
              <div className="check">
                <img className="checkbox" src={checkbox} alt="internship"/> 
                <img  className="sure" src={surity} alt="criteria"/>
              </div>
            </li>


            <li>
              <p>• interview preparation free</p>
              <div className="check">
                <img  className="checkbox" src={checkbox} alt="internship"/> 
                <img  className="sure" src={surity} alt="internship"/>
              </div>
            </li>
          </ul>
        </div>
<div>

<p className="end-line">NOW YOU CAN START YOUR Career from codeto</p>
</div>
    
    </div>
  );
};

export default Criteria;
